<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Analysis</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="success"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-analysis-conversions' }"
                >
                  Conversions
                </v-btn>
              </template>
              <span>View Conversions</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Analysis Info</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Visits</th>
                  <td>{{ visits[0].visits }}</td>
                  <th>Pageviews</th>
                  <td>{{ pageviews[0].pageviews }}</td>
                </tr>
                <tr>
                  <th>Bounce rate</th>
                  <td>{{ formatFloat(bounce_rate[0].bounce_rate) }}</td>

                  <th>AVG duration</th>
                  <td>{{ formatFloat(avg_duration[0].avg_duration) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="deleteArticle.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Article</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteArticle.article.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteArticle"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteArticle.loading"
            @click="saveDeleteArticle"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    appColor: {
      type: String,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Analysis",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "Visits", value: "visits" },
        { text: "Referrer Hostname", value: "referrer_hostname" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      viewsHeaders: [
        { text: "Date", value: "date" },
        { text: "Pageviews", value: "pageviews" },
        { text: "Referrer Hostname", value: "referrer_hostname" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteArticle: {
        dialog: false,
        article: {},
        loading: false,
      },
    };
  },

  computed: {
    visits() {
      return this.$store.state.analysis["visits"];
    },

    pageviews() {
      return this.$store.state.analysis["pageviews"];
    },

    bounce_rate() {
      return this.$store.state.analysis["bounce_rate"];
    },

    avg_duration() {
      return this.$store.state.analysis["avg_duration"];
    },
  },

  methods: {
    formatFloat(value) {
      return parseFloat(value).toFixed(2);
    },

    openDeleteArticle(article) {
      this.deleteArticle.article = article;
      this.deleteArticle.dialog = true;
    },

    resetDeleteArticle() {
      this.deleteArticle.dialog = false;
      this.deleteArticle.article = {};
      this.deleteArticle.loading = false;
    },

    saveDeleteArticle() {
      this.deleteArticle.loading = true;

      this.$store
        .dispatch("analysis/deleteArticle", {
          appId: this.$route.params.id,
          articleId: this.deleteArticle.article.id,
        })
        .then(() => {
          this.resetDeleteArticle();
        })
        .catch(() => {
          this.deleteArticle.loading = false;
        });
    },
  },
};
</script>
